import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import getTextColor from "../../../../../utils/controllers/getTextColor";
import "../styles/references.css";
import { getColorOpacityPercentage } from "../../../../../utils/controllers/colors";

function References({ data, handeLinkClick }) {
  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );
  //get referenceArticle section header
  const referenceArticle = useSelector(
    (state) => state.language.data?.referenceArticle
  );

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);
  const sliderRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  // Check if the slider is scrollable
  const checkScrollable = () => {
    const slider = sliderRef.current;
    if (slider) {
      setIsScrollable(slider.scrollWidth > slider.clientWidth);
    }
  };

  // Handle the 'Next' button
  const scrollNext = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  // Handle the 'Previous' button
  const scrollPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  // Check scrollable state on mount and resize
  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [data]); // Ensure effect runs when `data` changes

  return (
    <div className="referenceContainer">
      {data?.reference?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "var(--mpr-3)",
            marginBottom: "var(--mpr-2)",
            marginTop: "var(--mpr-2)",
          }}
        >
          <svg
            width="20"
            height="12"
            viewBox="0 0 20 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0.25C4.47501 0.25 3.01247 0.855748 1.93414 1.93408C0.855801 3.01242 0.25 4.47501 0.25 6C0.25 7.52499 0.855801 8.98758 1.93414 10.0659C3.01247 11.1443 4.47501 11.75 6 11.75C6.19891 11.75 6.38967 11.6709 6.53033 11.5303C6.67098 11.3896 6.75 11.1989 6.75 11C6.75 10.8011 6.67098 10.6104 6.53033 10.4697C6.38967 10.3291 6.19891 10.25 6 10.25C4.87283 10.25 3.79183 9.80216 2.9948 9.00513C2.19777 8.2081 1.75 7.12717 1.75 6C1.75 4.87283 2.19777 3.7919 2.9948 2.99487C3.79183 2.19784 4.87283 1.75 6 1.75C6.19891 1.75 6.38967 1.67093 6.53033 1.53027C6.67098 1.38962 6.75 1.19891 6.75 1C6.75 0.801088 6.67098 0.610379 6.53033 0.469727C6.38967 0.329074 6.19891 0.25 6 0.25Z"
              fill={primaryColor}
            />
            <path
              d="M14 0.25C13.8011 0.25 13.6103 0.329074 13.4697 0.469727C13.329 0.610379 13.25 0.801088 13.25 1C13.25 1.19891 13.329 1.38962 13.4697 1.53027C13.6103 1.67093 13.8011 1.75 14 1.75C15.1272 1.75 16.2082 2.19784 17.0052 2.99487C17.8022 3.7919 18.25 4.87283 18.25 6C18.25 7.12717 17.8022 8.2081 17.0052 9.00513C16.2082 9.80216 15.1272 10.25 14 10.25C13.8011 10.25 13.6103 10.3291 13.4697 10.4697C13.329 10.6104 13.25 10.8011 13.25 11C13.25 11.1989 13.329 11.3896 13.4697 11.5303C13.6103 11.6709 13.8011 11.75 14 11.75C15.525 11.75 16.9875 11.1443 18.0659 10.0659C19.1442 8.98758 19.75 7.52499 19.75 6C19.75 4.47501 19.1442 3.01242 18.0659 1.93408C16.9875 0.855748 15.525 0.25 14 0.25Z"
              fill={primaryColor}
            />
            <path
              d="M6 5.25C5.80109 5.25 5.61033 5.32907 5.46967 5.46973C5.32902 5.61038 5.25 5.80109 5.25 6C5.25 6.19891 5.32902 6.38962 5.46967 6.53027C5.61033 6.67093 5.80109 6.75 6 6.75H14C14.1989 6.75 14.3897 6.67093 14.5303 6.53027C14.671 6.38962 14.75 6.19891 14.75 6C14.75 5.80109 14.671 5.61038 14.5303 5.46973C14.3897 5.32907 14.1989 5.25 14 5.25H6Z"
              fill={primaryColor}
            />
          </svg>
          <p style={{ fontWeight: 800, fontSize: "1.1rem" }}>
            {referenceArticle}:
          </p>
        </div>
      )}
      {isScrollable && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "var(--mpr-3)",
            marginBottom: "var(--mpr-2)",
          }}
        >
          <>
            <button onClick={scrollPrev}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 21 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.32056 0.959713C5.7274 0.644814 6.21445 0.450394 6.72632 0.398678C7.23818 0.346961 7.75421 0.440077 8.21582 0.667232C8.67743 0.894388 9.06609 1.24647 9.3374 1.68359C9.60871 2.12071 9.75178 2.62518 9.75049 3.13964V5.24975H20.0005C20.1994 5.24975 20.3901 5.32883 20.5308 5.46948C20.6714 5.61013 20.7505 5.80084 20.7505 5.99975C20.7505 6.19866 20.6714 6.38937 20.5308 6.53003C20.3901 6.67068 20.1994 6.74975 20.0005 6.74975H9.75049V8.85986C9.75178 9.37433 9.60871 9.8788 9.3374 10.3159C9.06609 10.753 8.67743 11.1051 8.21582 11.3323C7.75421 11.5594 7.23818 11.6525 6.72632 11.6008C6.21445 11.5491 5.7274 11.3547 5.32056 11.0398L1.60059 8.17968C1.26675 7.92273 0.996343 7.59256 0.810303 7.2146C0.624262 6.83663 0.527344 6.42102 0.527344 5.99975C0.527344 5.57848 0.624262 5.16288 0.810303 4.78491C0.996343 4.40694 1.26675 4.07678 1.60059 3.81982L5.32056 0.959713ZM6.24048 2.13964C6.42635 1.99825 6.64812 1.91189 6.88062 1.88989C7.11311 1.86789 7.34706 1.91135 7.55615 2.01538C7.76524 2.1194 7.94103 2.2798 8.06372 2.47851C8.18642 2.67722 8.25115 2.90611 8.25049 3.13964V8.85986C8.25115 9.0934 8.18642 9.32228 8.06372 9.52099C7.94103 9.7197 7.76524 9.8801 7.55615 9.98413C7.34706 10.0882 7.11311 10.1316 6.88062 10.1096C6.64812 10.0876 6.42635 10.0013 6.24048 9.85986L2.52051 6.98974C2.36885 6.87309 2.2459 6.72316 2.16138 6.55151C2.07685 6.37986 2.03296 6.19108 2.03296 5.99975C2.03296 5.80842 2.07685 5.61964 2.16138 5.44799C2.2459 5.27634 2.36885 5.12642 2.52051 5.00976L6.24048 2.13964Z"
                  fill={primaryColor}
                />
              </svg>
            </button>
            <button onClick={scrollNext}>
              {" "}
              <svg
                width="16"
                height="16"
                viewBox="0 0 21 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.6799 11.0398C15.2731 11.3547 14.786 11.5491 14.2742 11.6008C13.7623 11.6525 13.2463 11.5594 12.7847 11.3323C12.3231 11.1051 11.9344 10.753 11.6631 10.3159C11.3918 9.8788 11.2487 9.37433 11.25 8.85986V6.74975H1C0.801088 6.74975 0.610379 6.67068 0.469727 6.53003C0.329074 6.38937 0.25 6.19866 0.25 5.99975C0.25 5.80084 0.329074 5.61013 0.469727 5.46948C0.610379 5.32883 0.801088 5.24975 1 5.24975H11.25V3.13964C11.2487 2.62518 11.3918 2.12071 11.6631 1.68359C11.9344 1.24647 12.3231 0.894388 12.7847 0.667232C13.2463 0.440077 13.7623 0.346961 14.2742 0.398678C14.786 0.450394 15.2731 0.644814 15.6799 0.959713L19.3999 3.81982C19.7337 4.07678 20.0041 4.40694 20.1902 4.78491C20.3762 5.16288 20.4731 5.57848 20.4731 5.99975C20.4731 6.42102 20.3762 6.83663 20.1902 7.2146C20.0041 7.59256 19.7337 7.92273 19.3999 8.17968L15.6799 11.0398ZM12.75 3.13964C12.7493 2.90611 12.8141 2.67722 12.9368 2.47851C13.0595 2.2798 13.2352 2.1194 13.4443 2.01538C13.6534 1.91135 13.8874 1.86789 14.1199 1.88989C14.3524 1.91189 14.5741 1.99825 14.76 2.13964L18.48 5.00976C18.6316 5.12642 18.7546 5.27634 18.8391 5.44799C18.9236 5.61964 18.9675 5.80842 18.9675 5.99975C18.9675 6.19108 18.9236 6.37986 18.8391 6.55151C18.7546 6.72316 18.6316 6.87309 18.48 6.98974L14.76 9.85986C14.5741 10.0013 14.3524 10.0876 14.1199 10.1096C13.8874 10.1316 13.6534 10.0882 13.4443 9.98413C13.2352 9.8801 13.0595 9.7197 12.9368 9.52099C12.8141 9.32228 12.7493 9.0934 12.75 8.85986V3.13964Z"
                  fill={primaryColor}
                />
              </svg>
            </button>
          </>
        </div>
      )}

      <div
        className="referenceWrapper"
        ref={sliderRef}
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        {data.reference?.map((article, index) => {
          // const background = {
          //   type: "color",
          //   color: "#eb4034",
          // };
          // const background = {
          //   type: "image",
          //   imageURL:
          //     "https://media.istockphoto.com/id/615422436/photo/demo-sign-cubes.jpg?s=612x612&w=0&k=20&c=HHOLIiF8SmbIssxKv3G480EgTVub_v9cc1QME3Dn6XU=",
          // };
          const background = article?.background;
          const type = background?.type;
          const color = background?.color;
          const imageURL = background?.imageURL;
          const isColor = type === "color";
          const isImage = type === "image";

          return (
            <div
              className="referenceCardParent"
              style={{
                backgroundColor: isColor ? color : bgColorOpacity,
                color: isColor ? getTextColor(color) : "var(--text-Color)",
                backgroundImage: isImage && `url(${imageURL})`,
              }}
            >
              <div
                className="referenceCard"
                style={{
                  backgroundColor: isImage && "var(--overlay)",
                  backdropFilter: isImage && "blur(2px)",
                  color: isImage && "white",
                }}
              >
                <h5 className="secondLine">{article?.title}</h5>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={article?.url}
                  onClick={() => handeLinkClick(article?.actionId)}
                >
                  <div className="referenceCardGradient"></div>
                  {article?.url}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default References;
