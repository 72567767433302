import React from 'react';
import TitleLeftBorder from '../../../../utils/components/TitleLeftBorder';
import { useSelector } from 'react-redux';
import ProgressCounter from '../../../../utils/presentation/progressCounter/workflow/ProgressCounter';
import Icon from '../../../../utils/components/Icon';

function TopBar({ isHideAnalytics }) {
  const languages = useSelector((state) => state.language.data);
  const { poweredBy_TEXT, engDesc_TEXT } = languages;
  const engagementData = useSelector((state) => state.flipcard.data.flipCard.engagement);

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  const { title, description, viewsCount } = engagementData;

  return (
    <div className="engTopBar">
      <div className="engTopbarDiv">
        <div className="engTopbarLogoDiv">
          {!logo && <p>{poweredBy_TEXT}</p>}
          <img
            onClick={() => {
              if (!logo) {
                window.open('https://www.bridged.media/', '_blank');
              }
            }}
            src={logo || bridgedLogo}
          />
        </div>
        <ProgressCounter />
      </div>
      <div className="relative">
        <TitleLeftBorder left="-20px">
          <div className="engTitleAndDesc">
            <h3>{title}</h3>
            <div>
              <p>{description || engDesc_TEXT}</p>
              {!isHideAnalytics && (
                <span>
                  <h5
                    className="primaryTextColor"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {isNaN(viewsCount) ? 1 : viewsCount + 1}
                  </h5>

                  <Icon
                    name="ri-user-line"
                    style={{
                      opacity: 0.4,
                      fontSize: '20px',
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </TitleLeftBorder>
      </div>
    </div>
  );
}

export default TopBar;
