import React from 'react';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';
import getTextColor from '../../../../../utils/controllers/getTextColor';
import KAReference from './KAReference';
import ChatLoader from '../../../../../utils/presentation/chatLoader/workflow/ChatLoader';
import References from '../../../reusable/components/references/References';
import { getColorOpacityPercentage } from '../../../../../utils/controllers/colors';

function KAAnswerChatBot({ data, responseAnswer, handeLinkClick, isBlur }) {
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);
  const referenceArticleTemplate = useSelector(
    (state) => state.flipcard.data.config.referenceArticleTemplate
  );
  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);

  const textColor = getTextColor(primaryColor) || 'white';

  const blurStyle = isBlur
    ? {
        filter: 'blur(4px)',
        pointerEvents: 'none',
        userSelect: 'none',
      }
    : {};

  return (
    <div className="kaAnswers">
      <div
        className="kaAnswersQuestion"
        style={{ backgroundColor: 'var(--primary-Color)', color: textColor }}
      >
        <h3>{data?.question}</h3>
      </div>
      {data?.isLoading ? (
        <div style={{ marginBottom: '20px' }}>
          <ChatLoader />
        </div>
      ) : (
        <div className="kaAnswersAnswer" style={blurStyle}>
          <Markdown
            className="kaAnswerMainDiv"
            components={{
              a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />,
            }}
          >
            {responseAnswer}
          </Markdown>
          {data?.reference?.length > 0 &&
            (referenceArticleTemplate === 'carousel' ? (
              <References
                data={data}
                bgColorOpacity={bgColorOpacity}
                handeLinkClick={handeLinkClick}
              />
            ) : (
              <KAReference data={data?.reference} handeLinkClick={handeLinkClick} />
            ))}
          {/* <KAReference data={data?.reference} handeLinkClick={handeLinkClick} /> */}
        </div>
      )}
    </div>
  );
}

export default KAAnswerChatBot;
