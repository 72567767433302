import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Custom hook to load GPT script
const useLoadGPTScript = (isActive) => {
  useEffect(() => {
    if (!isActive) return;

    // Check if script is already loaded
    if (document.querySelector('script[src*="gpt.js"]')) return;

    // Create and load GPT script
    const script = document.createElement('script');
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    script.async = true;
    script.onload = () => {
      // Initialize GPT after script loads
      window.googletag = window.googletag || { cmd: [] };
    };
    document.head.appendChild(script);

    // Cleanup
    return () => {
      const scriptElement = document.querySelector('script[src*="gpt.js"]');
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, [isActive]);
};

function AdManagerYnet() {
  const isActive = useSelector((state) => state.flipcard.data?.config?.googleAdConfig?.isActive);

  // Load GPT script
  useLoadGPTScript(isActive);

  useEffect(() => {
    if (!isActive) return;

    // Wait for GPT to be ready
    const initializeAd = () => {
      if (!window.googletag) return;

      // Define the ad slot
      window.googletag.cmd.push(function () {
        window.googletag
          .defineSlot('/6870/ynet/desktop/strip.AI', [728, 90], 'div-gpt-ad-1743079528665-0')
          .addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
      });

      // Display the ad
      window.googletag.cmd.push(function () {
        window.googletag.display('div-gpt-ad-1743079528665-0');
      });
    };

    // Check if GPT is already loaded
    if (window.googletag) {
      initializeAd();
    } else {
      // Wait for GPT to be ready
      const checkGPT = setInterval(() => {
        if (window.googletag) {
          initializeAd();
          clearInterval(checkGPT);
        }
      }, 100);
    }

    // Cleanup function
    return () => {
      if (window.googletag) {
        window.googletag.destroySlots();
      }
    };
  }, [isActive]);

  if (!isActive) return null;

  return (
    <div
      id="div-gpt-ad-1743079528665-0"
      style={{
        minWidth: '728px',
        minHeight: '90px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
}

export default AdManagerYnet;
