import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ScrollAnimation from '../../../../../utils/components/scrollAnimation/ScrollAnimation';
import KAAnswer from './KAAnswer';
import Divider from '../../../../../utils/components/Divider';
import KAReference from './KAReference';
import KARatings from './KARatings';
import KATutors from './KATutors';
import KADEA from './KADEA';
import { getColorOpacityPercentage } from '../../../../../utils/controllers/colors';
import References from '../../../reusable/components/references/References';
function KABody({
  responseData,
  handeLinkClick,
  handRatingTake,
  isMobileView,
  isAlwaysScrollable,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const scrollableBodyRef = useRef(null);
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isRagExceedsScroll = useSelector((state) => state.app.isRagExceedsScroll);
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);
  const referenceArticleTemplate = useSelector(
    (state) => state.flipcard.data.config.referenceArticleTemplate
  );

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);

  useEffect(() => {
    if (!isRagCollapsed) {
      setTimeout(() => {
        function handleScroll() {
          if (scrollableBodyRef.current) {
            const { scrollHeight, scrollTop, clientHeight } = scrollableBodyRef.current;
            const isScrollable = scrollHeight > clientHeight;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 15; // Adjusted for the 20px safe zone

            if (isScrollable) {
              if (isAtBottom) {
                setIsVisible(false);
              } else {
                setIsVisible(true);
              }
            } else {
              setIsVisible(false);
            }
          }
        }

        handleScroll();

        if (scrollableBodyRef.current) {
          scrollableBodyRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
          if (scrollableBodyRef.current) {
            scrollableBodyRef.current.removeEventListener('scroll', handleScroll);
          }
        };
      }, 500);
    }
  }, [scrollableBodyRef, isRagCollapsed]);

  useEffect(() => {
    if (scrollableBodyRef.current) {
      scrollableBodyRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [responseData]);

  const isKAScrollable = () => {
    if (isAlwaysScrollable) {
      return 'scroll';
    }
    if (isMobileView) {
      return '';
    }

    if (responseData?.length > 1) {
      return 'scroll';
    }
    if (isRagExceedsScroll) {
      return 'scroll';
    }
  };

  if (responseData?.length) {
    return (
      <div
        style={{
          overflow: isKAScrollable(),
        }}
        className="kaBody customScrollBar"
        ref={scrollableBodyRef}
      >
        {responseData?.map((data, index) => {
          const reverseIndex = responseData.length - 1 - index;
          return (
            <div key={index}>
              <KAAnswer data={data} responseAnswer={data?.response} />
              <KADEA data={data} />

              {data?.reference?.length > 0 &&
                (referenceArticleTemplate === 'carousel' ? (
                  <References
                    data={data}
                    bgColorOpacity={bgColorOpacity}
                    handeLinkClick={handeLinkClick}
                  />
                ) : (
                  <KAReference data={data?.reference} handeLinkClick={handeLinkClick} />
                ))}

              <KATutors data={data?.tutors} />

              <KARatings data={data?.feedback} handRatingTake={handRatingTake} index={index} />

              {index !== responseData?.length - 1 && <Divider style={{ margin: '0px' }} />}
            </div>
          );
        })}
        {!isRagCollapsed && <ScrollAnimation isVisible={isVisible} style={{ left: '90%' }} />}
      </div>
    );
  }

  return <></>;
}

export default KABody;
