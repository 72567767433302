import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlipcard } from '../../redux/slices/flipcardSlice';
import useErrorHandler from '../presentation/error/controllers/useErrorHandler';
import usePostMessage from './usePostMessage';
import getFlipcardScreenShot from './getFlipcardScreenShot';
import {
  setIsRagCollapsed,
  setIsRagExceedsScroll,
  setIsXrayCollapsed,
  setKaQuestionTrigger,
} from '../../redux/slices/appSlice';

const useCommunication = () => {
  const dispatch = useDispatch();
  const { postMessage } = usePostMessage();
  const { postError } = useErrorHandler();

  const viewportHeight = useSelector((state) => state.flipcard.data.config?.viewportHeight);
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isEngTaken = useSelector((state) => state.app.isEngTaken);
  const isFlipped = useSelector((state) => state.app.isFlipped);
  const isEndScreen = useSelector((state) => state.app.isShowEndScreen);
  const flipcard = useSelector((state) => state.flipcard);
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isRagFirstQuestion = useSelector((state) => state.app.isRagFirstQuestion);
  const isRagTwoQuestionAsked = useSelector((state) => state.app.isRagTwoQuestionAsked);
  const [appLoadedAck, setAppLoadedAck] = useState(false);
  const [isGateOpen, setIsGateOpen] = useState(true);
  const [isCardFocused, setIsCardFocused] = useState(false);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const listen = (event) => {
    const data = event.data;
    try {
      const parsedJson = JSON.parse(data);
      parseReceivedEvent(parsedJson);
    } catch (e) {}
  };

  const parseReceivedEvent = (parsedJson) => {
    try {
      if (parsedJson.flipCard != null) {
        receiveConfigurationAck(parsedJson);
      } else if (parsedJson.event != null) {
        eventHandler(parsedJson);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const appLoadedEventTrigger = () => {
    setTimeout(() => {
      postMessageWithAck({
        event: 'appLoaded',
        trackerId: getQueryParam('trackerId'),
      });
    });
  };

  const receiveConfigurationAck = (flipcardData) => {
    postMessage({
      event: 'configurationAck',
      trackerId: getQueryParam('trackerId'),
    });

    dispatch(setFlipcard(flipcardData));
    closeGate();
    ackEventHandler();
  };

  const closeGate = () => {
    setIsGateOpen(false);
  };

  const eventHandler = (parsedJson) => {
    switch (parsedJson.event) {
      case 'appLoadedAck':
        ackEventHandler();
        break;
      case 'screenshot':
        screenShotEventHandler();
        break;
      case 'isXrayCollapsed':
        xrayCollapseEventHandler(parsedJson);
        break;
      case 'cardFocused':
        cardFocusedHandler();
        break;
      case 'kaQuestionTrigger':
        kaQuestionTrigger(parsedJson);
        break;
      default:
    }
  };

  const postMessageWithAck = async (message) => {
    let numberOfTimesSent = 0;
    while (numberOfTimesSent < 5 && !appLoadedAck) {
      postMessage(message);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setAppLoadedAck((prevAppLoadedAck) => {
        if (prevAppLoadedAck) {
          numberOfTimesSent = 5;
        }
        return prevAppLoadedAck;
      });
      numberOfTimesSent++;
    }

    if (numberOfTimesSent === 5) {
      handleAckTimeoutError();
    }
  };

  const handleAckTimeoutError = async () => {
    postError('AppLoaded acknowledgement not received');
  };

  const ackEventHandler = () => {
    setAppLoadedAck(true);
  };

  const cardFocusedHandler = () => {
    setIsCardFocused(true);
  };

  const kaQuestionTrigger = (parsedJson) => {
    if (parsedJson?.value) {
      dispatch(setKaQuestionTrigger(parsedJson?.value));
    }
  };

  const screenShotEventHandler = async () => {
    const base64Image = await getFlipcardScreenShot(isFlipped, isEndScreen);
    postMessage({ image: base64Image });
  };

  const xrayCollapseEventHandler = (parsedJson) => {
    const value = parsedJson?.value;

    if (typeof value === 'boolean') {
      dispatch(setIsXrayCollapsed(value));
      dispatch(setIsRagCollapsed(value));

      postMessage({
        event: 'toggleCollapsion',
        trackerId: getQueryParam('trackerId'),
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', listen, true);

    return () => {
      window.removeEventListener('message', listen, true);
    };
  }, [isFlipped, isEndScreen]);

  useEffect(() => {
    appLoadedEventTrigger();
  }, []);

  // useEffect(() => {
  //   const trackerId = getQueryParam("trackerId");
  //   if (trackerId) {
  //     appLoadedEventTrigger();
  //   }
  // }, [getQueryParam("trackerId")]);

  useEffect(() => {
    if (flipcard?.data?.config) {
      if (!getQueryParam('pageURL') && !flipcard?.data?.config?.pageURL) {
        postError('Page URL Not Recieved in config object');
      }
    }
  }, [flipcard.data?.config]);

  const calculateCardMetrics = (isFlipped) => {
    const cards = document.querySelectorAll('.bridgedCard');
    if (cards?.length === 0) return null;

    const card = isFlipped ? cards[cards?.length - 1] : cards[0];
    const scrollHeight = card.scrollHeight;
    const clientHeight = card.clientHeight;

    return {
      scrollHeight,
      clientHeight,
      difference: scrollHeight - clientHeight,
    };
  };

  // Main function that calls the calculation and handles overflow
  const handleContentOverflow = (isFlipped, count) => {
    const metrics = calculateCardMetrics(isFlipped);
    if (!metrics || metrics.clientHeight === 0 || metrics.difference <= 0) return;

    const { scrollHeight, clientHeight, difference } = metrics;

    postMessage({
      event: 'overflow',
      pixels: difference,
      totalHeight: scrollHeight,
      trackerId: getQueryParam('trackerId'),
    });

    if (count === 0) {
      postError(
        'Overflow',
        { scrollHeight, clientHeight, difference },
        difference > 200 ? 'CRITICAL' : 'WARNING'
      );
    }

    if (count === 4 && difference >= 0) {
      postError(
        'Overflow Failed',
        { scrollHeight, clientHeight, difference },
        difference > 200 ? 'CRITICAL' : 'WARNING'
      );
    }
  };

  const sendOverflowRepeatedly = (isFlipped) => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= 5) {
        clearInterval(intervalId);
        return;
      }
      handleContentOverflow(isFlipped, count);
      count++;
    }, 2000);

    return intervalId;
  };

  const sendOverflowOnce = (isFlipped) => {
    setTimeout(() => {
      handleContentOverflow(isFlipped, 0);
    }, 100);
  };

  // ---------- Overflow for rest of the Data --------

  useEffect(() => {
    if (flipcard.data?.flipCard) {
      const isXray =
        flipcard.data?.flipCard?.engagement?.engagementData?.engagementType === 'x-ray';
      const isRag = flipcard.data?.flipCard?.engagement?.engagementData?.engagementType === 'rag';

      const isInline = flipcard.data?.config?.injectionStyle === 'inline';

      if (isXray && !isInline) {
        return;
      }

      if (isXray && isInline && isXrayCollapsed) {
        return;
      }

      if (isRag) {
        return;
      }

      sendOverflowOnce(isFlipped);

      const intervalId = sendOverflowRepeatedly(isFlipped);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount or dependency change
      };
    }
  }, [flipcard.data, isFlipped, isXrayCollapsed]);

  // ------------ Overflow for RAG ----------

  useEffect(() => {
    if (flipcard.data?.flipCard && isCardFocused) {
      // if (flipcard.data?.flipCard) {
      const isRag = flipcard.data?.flipCard?.engagement?.engagementData?.engagementType === 'rag';

      const isInline = flipcard.data?.config?.injectionStyle === 'inline';

      if (!isRag) {
        return;
      }

      const metrics = calculateCardMetrics(isFlipped);

      if (!metrics) return;

      const { scrollHeight } = metrics;

      if (!isInline) {
        // Check if scrollHeight is bigger than viewportHeight or within 20% of it
        if (
          scrollHeight > viewportHeight ||
          scrollHeight > viewportHeight * 0.8 // 20% headroom
        ) {
          dispatch(setIsRagExceedsScroll(true));
          return;
        }
      }

      sendOverflowOnce(isFlipped);

      const intervalId = sendOverflowRepeatedly(isFlipped);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount or dependency change
      };
    }
  }, [
    flipcard.data,
    isRagCollapsed,
    isRagFirstQuestion,
    isRagTwoQuestionAsked,
    isCardFocused,
    viewportHeight,
  ]);

  return {
    appLoadedAck,
    isGateOpen,
  };
};

export default useCommunication;
